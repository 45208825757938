import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { getAllPrograms, getEnrolledPrograms } from "../../../services/founderService";
import ErrorIndicator from "../../../Components/ErrorIndicator";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { LangContext, LangContextType, useAsync } from "../../../utilities/customHooks";
import * as T from "../../../utilities/frontendTypes";
import ContentPageTopButtons from "../../../Components/ContentPageTopButtons";
import { useContext, useEffect, useState } from "react";
import { contentPageContainerStyle, contentPageWrapperStyle } from "../../AdminPages/ProfilePages/PartnerAdminList";
import { DetailsContentHeader } from "../../AdminPages/ProfilePages/NewAdmin";
import { ProgramsAccordion } from "./FounderProgramList";
import { getUserProfile } from "../../../services/userService";
import textData from "../../../textData.json";
import { programsContainerStyle } from "../../AdminPages/ProgramsPages/AllPrograms";
import Card from "../../../Components/Card";
import Button from "../../../Components/Button";


function FounderServiceList() {
  const { langState } = useContext(LangContext) as LangContextType

  const location = useLocation();
  
  const isMyServices = (location.pathname === "/dashboard/enrolled-services")

  useEffect(() => {
    setIsDetailsViewOpen(location.pathname !== "/dashboard/enrolled-services");
  }, [location]);

  const [isDetailsViewOpen, setIsDetailsViewOpen] = useState(false);

  const navigate = useNavigate();
  
  const allProgramsAsync = useAsync(() => getAllPrograms(), []);

  if (allProgramsAsync.status === "pending") {
    return <LoadingIndicator />;
  }

  if (allProgramsAsync.status === "rejected") {
    return <ErrorIndicator />;
  }

  const allPrograms = allProgramsAsync.value.regionSpecificPrograms;

  return (
    <div className="resourcesCont !pt-0">
      <h1>{textData.Pages.FounderPages.ProgramPages.FounderServiceList.Title[langState]}</h1>
        <div className="w-full flex flex-col gap-4 !max-w-[1024px]">
          <h2 className="font-semibold">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.EdTitle[langState]}</h2>
          <p className="bodyLarger">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.EdBody[langState]}</p>
          <p className="font-semibold bodyLarger">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.EdBody2[langState]}</p>
          <p className="bodyLarger">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.EdBody3[langState]}</p>
          <div className="flex flex-col">
            <a className="bodyLarger underline" target="_blank" href="https://drive.google.com/file/d/10ghbQGpJmGfgCdSY2lYNCsEVdwv5YWTh/view">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.EdLink1[langState]}</a>
            <a className="bodyLarger underline" target="_blank" href="https://elevateip-learninghub.communitech.ca/">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.EdLink2[langState]}</a>
          </div>
        </div>
        <div className="flex justify-between w-full learningPageCont">
          <div className="flex flex-col justify-end pathCont">
            <h2>{textData.Pages.FounderPages.ProgramPages.FounderServiceList.PathTitle[langState]}</h2>
            <h3 className="mb-2">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.PathSubTitle[langState]}</h3>
            <p className="mb-6">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.PathBlurb[langState]}</p>
            <Button
              buttonText={textData.Pages.FounderPages.ProgramPages.FounderServiceList.PathButton[langState]}
              buttonType="primaryFull"
              actionHandler={() => navigate("/dashboard/learning-pathway")}
            />
          </div>
          <div className="flex flex-col justify-end">
            <h2 className="mb-2">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.Curated[langState]}</h2>
            <p>{textData.Pages.FounderPages.ProgramPages.FounderServiceList.CuratedBlurb1[langState]}</p>
            <p className="mb-6">{textData.Pages.FounderPages.ProgramPages.FounderServiceList.CuratedBlurb2[langState]}</p>
            <Button
              buttonText={textData.Pages.FounderPages.ProgramPages.FounderServiceList.Explore[langState]}
              buttonType="secondaryFull"
              actionHandler={() => navigate("/dashboard/resources")}
            />
          </div>
        </div>
        {/* <div className="">
          <h2>{textData.Pages.FounderPages.ProgramPages.FounderServiceList.Events[langState]}</h2>
          {allPrograms.length === 0 ?
          <div className="bodyLarger mt-4">
            No Events Scheduled
          </div>
          :
          <div>
            {
              allPrograms.map(program => (
                <Card
                  key={program._id}
                  program={program}
                  isViewOnly={false}
                />
              ))
            }
          </div>
        }
        </div> */}
      </div>
  )
}


export default FounderServiceList