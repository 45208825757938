import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addCircles, sortObjectsByKey } from "../utilities/helperFunctions";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";

export type ListRowContentValue = string | Date | number | any  // [TODO] non-breaking, unsafe 'any' time, fix if time.

export type KeyValuePair = {
  [key: string]: ListRowContentValue;
}

export type ColumnHeader = {
  header: string,
  key: string,
  sortOptions?: ListRowContentValue[],
  filterFunc?: () => void
}

function List ( 
  {
    contentArray, 
    columnHeaderData,
    detailsIdName,
    returnDetailsId,
    listHighlightId,
    defaulSortOrderArrayPosition, 
    defaultAscending,
    defaultOptionsOrder,
    defaultOptionPosition,
    deaultSortByKey,
    emailArrayChange,
    personaMin,
    checkbox,
    isReverse
  } 
  : 
  { 
    contentArray: KeyValuePair[],
    columnHeaderData: ColumnHeader[],
    detailsIdName?: string,
    returnDetailsId?: (id: string) => void, 
    listHighlightId?: string,
    defaulSortOrderArrayPosition?: number,
    defaultAscending?: boolean
    defaultOptionsOrder?: {
      sortOptions: any[];
      currentOptionIndex: number;
    },
    defaultOptionPosition?: number,
    deaultSortByKey?: string,
    emailArrayChange?: (array: string[])=> void,
    personaMin?: number,
    checkbox?: boolean
    isReverse?: boolean
  }
) {
  const [rowsHTML, setRowsHtml] = useState<React.ReactNode | null>(null);
  useEffect (() => {
  addCircles()
  }, [rowsHTML]);
  
  const headerMatchingContentArray = contentArray.map((contentObj) => {
    const filteredObject: KeyValuePair = {};
    columnHeaderData.forEach(columnHeader => {
      const key = columnHeader.key;
      if (contentObj.hasOwnProperty(key)) {
        filteredObject[key] = contentObj[key];
      }
    }) 
    if (detailsIdName) {
      filteredObject[detailsIdName] = contentObj[detailsIdName];
    }
    return filteredObject;
  });
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const defaultAscendingBoolean: boolean = defaultAscending ? defaultAscending : false
  const [isAscending, setIsAscending] = useState(defaultAscendingBoolean);
  const initialSotredContentArray = sortObjectsByKey(headerMatchingContentArray, columnHeaderData[0].key, true);
  const [sortedContentArray, setSortedContentArray] = useState(initialSotredContentArray)
  const [sortByKey, setSortByKey] = useState(deaultSortByKey ? deaultSortByKey : "");
  const [currentOptionIndex, setCurrentOptionIndex] = useState(defaultOptionPosition ? defaultOptionPosition - 1 : 0);
  const optionsToggle = (options: ListRowContentValue[], headerKey: string) => {
    let sendOption = 0
    if (currentOptionIndex < options.length - 1) {
      sendOption = currentOptionIndex + 1
      setCurrentOptionIndex(i => (i + 1));
    } else {
      setCurrentOptionIndex(0);
    }
    setSortedContentArray(sortObjectsByKey(sortedContentArray, headerKey, true, {sortOptions: options, currentOptionIndex: sendOption}));
  };

  // useEffect(() => {
  //   if (defaultOptionPosition !== undefined && defaultOptionsOrder !== undefined) {
  //     optionsToggle(defaultOptionsOrder.sortOptions)
  //   }
  // }, []);

  useEffect(() => {
    if (emailArrayChange) {
      emailArrayChange(selectedUsers)
    }
  }, [selectedUsers]);

  const headerHTML = columnHeaderData.map((columnHeader, index) => {
    const headerKey = columnHeader.key;
    // [TODO] Alter sortOptions array to filter out options that no piece of content uses, add indicator when sortOptions is the key
    const sortOptions = columnHeader.sortOptions;
    const isActive = columnHeader.key === sortByKey; // for displaying ascending indicator on the active column
    const isLast = index === columnHeaderData.length - 1; // for UI border right on the last column

    return (
      <div 
        className={`${columnHeaderData.length > 5 ? `largeGrid flex items-center ${sortOptions && isActive ? "flex-col" : ""}` : `flex w-full ${sortOptions && isActive ? "flex-col" : ""}`}`}
        key={headerKey}
      >
        <p className={`mr-2 py-4 pl-4 text-cSecondaryGreen ${sortOptions && isActive ? "flex w-full !py-0" : ""}`}>{columnHeader.header}</p>
        { sortOptions ?
          <button
          className={`grow text-cSecondaryGreen cursor-pointer mr-2 ${sortOptions && isActive ? "flex w-full" : ""}`}
          onClick={
            () => {
              
              setSortByKey(headerKey);
              optionsToggle(sortOptions, headerKey);
            }
          }
        >          
        {sortOptions && isActive ? <span className="bodySmaller pl-4">{sortOptions[currentOptionIndex]}</span> : <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><path d="M9.07406 6.59389C9.00318 6.59389 8.9308 6.55831 8.87632 6.4909L5.17769 1.84724L1.47828 6.4909C1.36785 6.62946 1.19101 6.62946 1.08282 6.4909C0.972392 6.35234 0.972392 6.13046 1.08282 5.99471L4.97916 1.10298C5.03139 1.03745 5.10228 1 5.1769 1C5.25153 1 5.3224 1.03745 5.37464 1.10298L9.27098 5.99471C9.38141 6.13327 9.38141 6.35515 9.27098 6.4909C9.21726 6.55831 9.14718 6.59389 9.07406 6.59389Z" fill="#678175" stroke="#678175" strokeWidth="0.34"/><path d="M5.17898 14.1004C5.02378 14.1004 4.89918 13.9431 4.89918 13.7493L4.89844 1.35107C4.89844 1.15634 5.02379 1 5.17824 1C5.33344 1 5.45804 1.15729 5.45804 1.35107V13.7493C5.45804 13.9431 5.33343 14.1004 5.17898 14.1004Z" fill="#678175" stroke="#678175" strokeWidth="0.34"/><path d="M11.8214 17.0011C11.7468 17.0011 11.6759 16.9636 11.6237 16.8981L7.72735 12.0064C7.61692 11.8678 7.61692 11.6459 7.72735 11.5102C7.83778 11.3716 8.01462 11.3716 8.12281 11.5102L11.8222 16.1519L15.5216 11.5102C15.6321 11.3716 15.8089 11.3716 15.9171 11.5102C16.0275 11.6487 16.0275 11.8706 15.9171 12.0064L12.0184 16.8981C11.9677 16.9636 11.896 17.0011 11.8214 17.0011Z" fill="#678175" stroke="#678175" strokeWidth="0.34"/><path d="M11.8228 16.9981C11.6676 16.9981 11.543 16.8409 11.543 16.6471L11.5437 4.24951C11.5437 4.05478 11.6691 3.89844 11.8235 3.89844C11.978 3.89844 12.1033 4.05572 12.1033 4.24951V16.6478C12.1033 16.8416 11.978 16.9981 11.8228 16.9981Z" fill="#678175" stroke="#678175" strokeWidth="0.34"/></svg>}
        </button>
        :
        <button
          className={`grow text-cSecondaryGreen cursor-pointer mr-2`}
          onClick={
            () => {
              setSortedContentArray(sortObjectsByKey(sortedContentArray, headerKey, isActive ? !isAscending : true));
              setIsAscending(isActive ? (b) => !b : true);
              setSortByKey(headerKey);
            }
          }
        >          
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><path d="M9.07406 6.59389C9.00318 6.59389 8.9308 6.55831 8.87632 6.4909L5.17769 1.84724L1.47828 6.4909C1.36785 6.62946 1.19101 6.62946 1.08282 6.4909C0.972392 6.35234 0.972392 6.13046 1.08282 5.99471L4.97916 1.10298C5.03139 1.03745 5.10228 1 5.1769 1C5.25153 1 5.3224 1.03745 5.37464 1.10298L9.27098 5.99471C9.38141 6.13327 9.38141 6.35515 9.27098 6.4909C9.21726 6.55831 9.14718 6.59389 9.07406 6.59389Z" fill="#678175" stroke="#678175" strokeWidth="0.34"/><path d="M5.17898 14.1004C5.02378 14.1004 4.89918 13.9431 4.89918 13.7493L4.89844 1.35107C4.89844 1.15634 5.02379 1 5.17824 1C5.33344 1 5.45804 1.15729 5.45804 1.35107V13.7493C5.45804 13.9431 5.33343 14.1004 5.17898 14.1004Z" fill="#678175" stroke="#678175" strokeWidth="0.34"/><path d="M11.8214 17.0011C11.7468 17.0011 11.6759 16.9636 11.6237 16.8981L7.72735 12.0064C7.61692 11.8678 7.61692 11.6459 7.72735 11.5102C7.83778 11.3716 8.01462 11.3716 8.12281 11.5102L11.8222 16.1519L15.5216 11.5102C15.6321 11.3716 15.8089 11.3716 15.9171 11.5102C16.0275 11.6487 16.0275 11.8706 15.9171 12.0064L12.0184 16.8981C11.9677 16.9636 11.896 17.0011 11.8214 17.0011Z" fill="#678175" stroke="#678175" strokeWidth="0.34"/><path d="M11.8228 16.9981C11.6676 16.9981 11.543 16.8409 11.543 16.6471L11.5437 4.24951C11.5437 4.05478 11.6691 3.89844 11.8235 3.89844C11.978 3.89844 12.1033 4.05572 12.1033 4.24951V16.6478C12.1033 16.8416 11.978 16.9981 11.8228 16.9981Z" fill="#678175" stroke="#678175" strokeWidth="0.34"/></svg>
        </button>
        }
        {
          columnHeader.filterFunc && 
          <button
          className={`grow text-cSecondaryGreen cursor-pointer mr-2`}
          onClick={
            () => columnHeader.filterFunc
          }
        >     
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none"><path d="M8.8728 16C8.82645 16 8.75646 16 8.7101 15.976L5.94162 14.9836C5.75529 14.9125 5.61623 14.7233 5.61623 14.535L5.61532 9.54811L0.263557 2.19799C-0.0391196 1.77242 -0.0854705 1.22869 0.147215 0.756044C0.3799 0.283398 0.845271 0 1.35699 0H13.643C14.1547 0 14.6201 0.283398 14.8528 0.756044C15.0855 1.22869 15.0391 1.77242 14.7364 2.19799L9.36188 9.54811V15.5273C9.36188 15.6926 9.29189 15.8347 9.15282 15.9289C9.08284 15.976 8.96642 16 8.8728 16ZM6.59321 14.2036L8.40834 14.8414L8.40743 9.38286C8.40743 9.2887 8.43106 9.17054 8.50014 9.09946L13.9692 1.63119C14.1092 1.44194 14.0392 1.2767 14.0155 1.20562C13.9919 1.13453 13.8756 0.993294 13.6665 0.993294H1.35792C1.12523 0.993294 1.03253 1.15854 1.00889 1.20562C0.985259 1.2767 0.916179 1.44194 1.05525 1.63119L6.52335 9.09946C6.59334 9.19362 6.61606 9.28871 6.61606 9.38286V14.2034L6.59321 14.2036Z" fill="#678175"/></svg>
        </button>
        }
      </div>
    )
  })

  const handleCheckboxChange = (checkedUser: string) => {
    setSelectedUsers(prevSelectedUsers => {
      const isSelected = prevSelectedUsers.includes(checkedUser);
      if (isSelected) {
        return prevSelectedUsers.filter(user => user !== checkedUser);
      } else {
        return [...prevSelectedUsers, checkedUser];
      }
    });
  };

  useEffect(() => {
    let filterArray = [...sortedContentArray]
    if (personaMin) {
      filterArray = filterArray.filter(obj => obj.personaScore >= personaMin);
      setSelectedUsers
    }
    const newRowsHTML = filterArray.map((content, index) => { 
      const headerMatchingContent = {...content};
      if (detailsIdName) delete headerMatchingContent[detailsIdName]
      const isLastRow = index === sortedContentArray.length - 1;
      const rowKey = detailsIdName ? content[detailsIdName] as string : index.toString(); 

      const rowHTML = Object.values(headerMatchingContent).map((v, i) => {
        if (typeof v === "string" && moment(v, moment.ISO_8601, true).isValid()) {
          v = moment(v).format('MMM Do YYYY').toString();
        }
        const isUrl = typeof v === "string" && v.includes("www.") 
        const isHttpsUrl = typeof v === "string" && v.includes("https://") 
        const urlHref = isHttpsUrl ? v : isUrl ? `https://${v}` : ""
        let displayContent = v
        if (v.hasOwnProperty("circle") && v.hasOwnProperty("action")) {
          displayContent = v.action as string
        } else if (v.hasOwnProperty("ownerName")) {
          displayContent = v.ownerName as string
        } else if (typeof v === "object") {
          displayContent = ""
        } else if (v === "Jan 1st 0000") {
          displayContent = "Unsubmitted"
        }
        return (
          checkbox && i === 0
          ?
          <div
            key={i}
            className={
              `
                flex justify-left items-center ${!isLastRow && "border-b"} border-borders border-b
                group-hover:bg-text/10 transition-colors duration-200 p-4 ${columnHeaderData.length > 5 ? "largeGrid" : ""}`
                // ${listHighlightId === rowKey && "bg-text/10"}
            }
          >        
            <input 
              type="checkbox"
              onChange={() => handleCheckboxChange(v)}
              className="mr-2"
            />
            <button onClick={() => {
              if (returnDetailsId && detailsIdName) {
                returnDetailsId(content[detailsIdName] as string);
              }
            }} className="flex grow font-normal text-text/80 break-all self-center">{displayContent}</button>
          </div>
          :
          i === 0 
          ?
            <button
            onClick={() => {
              if (returnDetailsId && detailsIdName) {
                returnDetailsId(content[detailsIdName] as string);
              }
            }}
            key={i}
            className={
              `
                flex justify-left items-center border-borders border-b
                group-hover:bg-text/10 transition-colors duration-200 p-4 ${columnHeaderData.length > 5 ? "largeGrid" : ""}`
                // ${listHighlightId === rowKey && "bg-text/10"}
            }
          >        
            <p className="flex grow font-normal text-text/80 break-word self-center">{displayContent}</p>
          </button>
          :
          <div
          key={i}
          className={
            `
              flex justify-left border-b border-borders items-center 
              group-hover:bg-text/10 transition-colors duration-200 p-4 ${columnHeaderData.length > 5 ? "largeGrid" : ""}`
              // ${listHighlightId === rowKey && "bg-text/10"}
          }
        >  
          {isUrl || isHttpsUrl ? <a className="underline font-normal" href={urlHref} target="_blank">{displayContent}</a> : <p className={`flex grow font-normal text-text/80 break-word self-center`}>{displayContent}</p>}      
        </div>
        )
      })
      
      return (
          <div
            key={rowKey}
            className={`grid ${columnHeaderData.length > 5 ? "flexList" : "contents"}`}
          >
            {rowHTML}
          </div>
      )
    });
    setRowsHtml(newRowsHTML)
  }, [personaMin, sortByKey, isAscending, currentOptionIndex]);


  let gridClassName = "";
  switch(columnHeaderData.length) {
    case 1:
      gridClassName = "grid-cols-1 w-full";
      break;
    case 2:
      gridClassName = "grid-cols-2 w-full";
      break;
    case 3:
      gridClassName = "grid-cols-3 w-full";
      break;
    case 4:
      gridClassName = "grid-cols-4 w-full";
      break;
    case 5:
      gridClassName = "grid-cols-5 w-full";
      break;
    // case 6:
    //   gridClassName = "grid-cols-6";
    //   break;
    // case 7:
    //   gridClassName = "grid-cols-7";
    //   break;
    // case 8:
    //   gridClassName = "grid-cols-8";
    //   break;
    // case 9:
    //   gridClassName = "grid-cols-9";
    //   break;
    // case 10:
    //   gridClassName = "grid-cols-10";
    //   break;
    default :
      gridClassName = `flex flex-col largeGrid`;
      break;
  }

  return (
    <div className="w-full">
        {columnHeaderData.length > 5 
        ? 
        <div className={`grid ${gridClassName} font-medium bg-backgrounds text-text overflow-y-auto mb-5 listComp`}>
            <div className="flex">
              {headerHTML}
            </div>
          {rowsHTML}
        </div>
        :
          <div className={`grid ${gridClassName} font-medium bg-backgrounds text-text overflow-y-auto mb-5 listComp`}>
            {headerHTML}
            {rowsHTML}
          </div>
        }

    </div>
  )
}

export default List;